.App {
  min-height: 100vh;
  background-image: linear-gradient(to top, #accbee 0%, #e7f0fd 100%);
} 

.search-form{
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar{
  width: 50%;
  border: none;
  padding: 10px;
}
 
.search-button{
  background: lightcoral;
  border: none;
  padding: 10px 20px;
  color: white;
}

.recipes{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}